define("ember-svg-jar/inlined/safari-pinned-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M74.4 1C67.5 2 55.8 5.7 49 8.9 18.3 23.7-.7 55.2.5 89.4c.8 22 9.7 42.7 25.1 58 17.1 16.9 39.2 26 62.3 25.5 33.4-.7 61.5-18.9 76-49.1 5.7-11.8 8.2-22.1 8.6-34.9.7-24-8-45.9-25-63.2-7.4-7.6-16.2-13.6-26.2-18C108 1.8 88.3-1 74.4 1z\"/>",
    "attrs": {
      "version": "1.0",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "230.667",
      "height": "230.667",
      "viewBox": "0 0 173 173"
    }
  };
  _exports.default = _default;
});
define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "cl.about": "About",
    "cl.buy": "Buy on Uniswap",
    "cl.buy.dextools": "Buy on Dextools",
    "cl.buy.uniswap": "Buy on UniSwap",
    "cl.buyNow": "Buy Now",
    "cl.chart": "Chart",
    "cl.connectWallet": "Connect Wallet",
    "cl.dextools": "Dextools",
    "cl.downloadWhitePaper": "WhitePaper",
    "cl.footer.links.ethereum": "Ethereum",
    "cl.footer.links.medium": "Medium",
    "cl.getStarted": "Get Started",
    "cl.howItWorks": "How it Works",
    "cl.howToBuy": "Buy $POCHI",
    "cl.integration": "Integration",
    "cl.loading": "Loading...",
    "cl.nftMint": "NFT Mint",
    "cl.roadmap": "Roadmap",
    "cl.staking": "Staking",
    "cl.title": "Pochi Inu - Token | NFT | Metaverse",
    "tokenomics": "Tokenomics",
    "tokenomics.buy-tax": "Buy Taxes",
    "tokenomics.max-wallet": "Max Wallet",
    "tokenomics.sell-tax": "Sell Taxes",
    "tokenomics.total-supply": "Total Supply"
  }]];
  _exports.default = _default;
});